import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IloggedUser, IUser } from 'src/app/shared/interfaces/auth';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: "root"
})
export class AuthService {
  LoggedInUser: BehaviorSubject<IloggedUser|null> = new BehaviorSubject<IloggedUser|null>(JSON?.parse(localStorage.getItem('user') || 'null'));
  userCart: BehaviorSubject<boolean>;
  Region: BehaviorSubject<any>;
  constructor(private http: HttpClient) {
    this.Region = new BehaviorSubject<any>(JSON?.parse(localStorage.getItem('selectedRegion') || 'null'))
    this.userCart = new BehaviorSubject<boolean>(true)
  }

  public get getLoggedInUserValue(): IloggedUser {
    return this.LoggedInUser.value;
  }

  SetUser(user: IloggedUser) {
    this.LoggedInUser.next(user);
  }

  Logout() {
    this.LoggedInUser.next(null);
    this.userCart.next(false)
    localStorage.removeItem('user')
  }

  Register(data: string) {
    return this.http.post(`${environment.server}User/RegisterUser`, data);
  }
  SendOTP(email: string,type:'ForgotPassword'|'Registration') {
    return this.http.post(`${environment.server}User/sendotp`,{email,type});
  }
  VerifyOTP(email: string, otp: number) {
    return this.http.post(`${environment.server}User/verifyotp`, { email, otp });
  }
  ResetPassword(email: string, password: string) {
    return this.http.post(`${environment.server}User/ResetPassword`, { email, password });
  }
  Login(data: any) {
    return this.http.post(`${environment.server}User/login`, data);
  }

}
