<a routerLink="/admin/dashboard" style="text-decoration: none;" class="simple-text logo d-flex align-items-center justify-content-center">
    <img src="../../../assets/img/tiffenawwbig.png"  style="height: 50px;" />
</a>
<div class="sidebar-wrapper">
    <div *ngIf="isMobileMenu()">
        <form class="navbar-form">
            <span class="bmd-form-group">
                <div class="input-group no-border">
                    <input type="text" value="" class="form-control" placeholder="Search...">
                    <button pButton type="submit" class="btn btn-white btn-round btn-just-icon">
                        <i class="pi pi-search"></i>
                        <div class="ripple-container"></div>
                    </button>
                </div>
            </span>
        </form>
        <ul class="nav navbar-nav nav-mobile-menu">
            <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)">
                    <i class="material-icons">dashboard</i>
                    <p>
                        <span class="d-lg-none d-md-block">Stats</span>
                    </p>
                </a>
            </li>
            <li class="nav-item dropdown">
                <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">notifications</i>
                    <span class="notification">5</span>
                    <p>
                        <span class="d-lg-none d-md-block">Some Actions</span>
                    </p>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item" href="#">Mike John responded to your email</a>
                    <a class="dropdown-item" href="#">You have 5 new tasks</a>
                    <a class="dropdown-item" href="#">You're now friend with Andrew</a>
                    <a class="dropdown-item" href="#">Another Notification</a>
                    <a class="dropdown-item" href="#">Another One</a>
                </div>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)">
                    <i class="material-icons">person</i>
                    <p>
                        <span class="d-lg-none d-md-block">Account</span>
                    </p>
                </a>
            </li>
        </ul>
    </div>
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
            <a class="nav-link" [routerLink]="[menuItem.path]">
                <i class="{{menuItem.icon}}"></i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
    </ul>
</div>