<div class="container-fluid m-0 p-0">
      <div class="row justify-content-center">
            <div class="col-sm-12 col-md-5 offset-md-1 px-4">
                  <div class="card text-start ta-box ">
                        <div class="card-body">
                              <div class="row header-section">
                                    <div class="col-2">
                                          <img src="../../assets/siteimages/logo400X400.webp" height="50px" alt="logo">
                                    </div>
                                    <div class="col d-flex-end ta-auth-button">
                                          <!-- <button routerLink="/auth/signup" pButton
                                                class="bg-dark fw-bold pull-right">Sign
                                                up</button>
                                    <button routerLink="/auth/login" pButton
                                                class="p-button-plain p-button-text fw-bold text-dark pull-right">Login</button> -->
                                          <button type="button" class="signup-btn bg-dark fw-bold pull-right text-white"
                                                routerLink="/auth/signup">Sign up</button>
                                          <button type="button" class="login-btn fw-bold pull-right text-dark"
                                                routerLink="/auth/login">Login</button>
                                    </div>
                              </div>
                        </div>
                  </div>
                  <div class="card text-start ta-box">
                        <div class="card-body">
                              <p [ngClass]="[ changeClass ? 'highLight-content-0' : 'highLight-content-1']"
                                    class="highlight fw-bold">
                                    {{highLight}}
                              </p>
                              <p class="text-secondary">Order food from favourite E Restaurant.</p>
                        </div>
                  </div>

                  <div class="card text-start ta-box">
                        <div class="card-body p-fluid">
                              <p-autoComplete [suggestions]="results" placeholder="Search your location..."
                                    [showEmptyMessage]="true" emptyMessage="Location not found." [showClear]="true"
                                    icon="pi pi-globe" (onSelect)="selectChange($event)"
                                    (completeMethod)="search($event)" field="label" class="search-input">
                              </p-autoComplete>
                        </div>
                  </div>
                  <div class="card text-start ta-box mb-0">
                        <div class="card-body ">
                              <p class="text-secondary mb-2">POPULAR REGIONS</p>
                              <p class="ta-popular-regions">
                                    <span class="ms-2 ib fw-bold text-secondary" style="cursor: pointer;"
                                          *ngFor="let place of availablePlaces; let i=index"
                                          (click)="newLocation(place)"
                                          [ngClass]="i%2 ==0? 'text-dark':''">{{place?.label}}</span>
                                    <span class="ms-2 fw-bold text-success"> & more</span>
                              </p>
                        </div>
                  </div>

                  <div class="card text-end ta-box mb-4">
                        <div class="card-body ta-box">
                              <p>
                                    <button pButton class="p-button-plain p-button-text fw-bold text-dark skip"
                                          routerLink="/public/home">Skip</button>
                              </p>
                        </div>
                  </div>
                  <div *ngIf="selected" class="card text-center ta-box landing-region-dialog px-2">
                        <p-dialog header="Confirm region" [(visible)]="showSelected" [modal]="true"
                              [style]="{width: '95vmin'}" [draggable]="false" (onHide)="closeDialog()">
                              <p class="text-font">We are time based for our order timing!</p>
                              <p class="text-font fs-2">{{selected?.label}}</p>
                              <p class="text-font">Lunch : {{selected?.lunch}}</p>
                              <p class="text-font">Dinner : {{selected?.dinner}}</p>
                              <ng-template pTemplate="footer">
                                    <div class="col text-center my-3">
                                          <button type="button" class="proceed-btn text-white"
                                                (click)="SetGo()">Proceed</button>
                                    </div>
                              </ng-template>
                        </p-dialog>
                  </div>
            </div>
            <div class="col-sm-12 col-md-6 tiffin-aww-image p-0">
                  <img src="../../../assets/img/registration-background.webp" alt="tiffin background" width="100%"
                        height="100%" class="rounded-t-5 rounded-tr-lg-0 rounded-bl-lg-5" />
            </div>
      </div>
      <div class="col-12 bg-dark ta-delivery d-mobile-none">
            <div class="container text-center">
                  <div class="row py-4">
                        <div class="col">
                              <img class="ta-footer-img" src="./../../assets/img/delivary_0.webp">
                              <div class="text-success">No Minimum Order</div>
                              <div class="text-light">Order in for yourself or for the group, with no restrictions
                                    on
                                    order value
                              </div>
                        </div>
                        <div class="col">
                              <img class="ta-footer-img" src="./../../assets/img/delivary_1.webp">
                              <div class="text-success">Live Order Tracking</div>
                              <div class="text-light">Know where your order is at all times, from the restaurant
                                    to your
                                    doorstep
                              </div>
                        </div>
                        <div class="col">
                              <img class="ta-footer-img" src="./../../assets/img/delivary_2-transformed.png">
                              <div class="text-success">Lightning-Fast Delivery</div>
                              <div class="text-light">Experience Tiffin Aww's superfast delivery for food
                                    delivered
                                    fresh &amp; on time
                              </div>
                        </div>
                  </div>
            </div>
      </div>
</div>