<div class="container-xxl text-dark bg-light">
  <div class="row pt-2">
    <div class="col-sm-12 col-md-4 serving-homemade">
      <img src="../../../assets/siteimages/logo400X400.webp" style="height: 90px;">
    </div>
    <div class="col-sm-12 col-md-4 my-2 address">
      <p>
        <i class="pi pi-map-marker me-2"></i> Srinagar,
        192101 J&K
      </p>
      <p>
        <i class="pi pi-phone me-2"></i> <a href="tel:+916006014287">++916006014287</a>
      </p>
      <p>
        <i class="pi pi-envelope me-2"></i> <a href="mailto:feedback@er.com">
          feedback@er.com</a>
      </p>
    </div>
    <div class="col-sm-12 col-md-4 my-1 text-center">
      <a href="https://wa.me/++916006014287" target="_blank" class="text-success"> <i
          class="pi pi-whatsapp fs-1 p-1"></i>
      </a>
      <a href="https://www.facebook.com/asifdar" target="_blank" class="text-primary"> <i
          class="pi pi-facebook fs-1 p-1"></i>
      </a>
      <a href="https://instagram.com/asifdar?igshid=YmMyMTA2M2Y=" target="_blank" class="text-danger"> <i
          class="pi pi-instagram fs-1 p-1"></i>
      </a>
    </div>
    <!-- <div class="col-lg-12 text-center">
      <span class="links green-vl" routerLink="/public/privacy-policy">
        Privacy Policy
      </span>
      <span class="links green-vl" routerLink="/public/terms-and-conditions">
        Terms and conditions
      </span>
      <span class="links green-vl" routerLink="/public/cancellation-and-refund">
        Cancellation and refund
      </span>
      <span class="links green-vl" routerLink="/public/shipping-delivery">
        Shipping and delivery
      </span>
      <span class="links" routerLink="/public/contact-us">
        Contact us
      </span>
    </div> -->
  </div>
</div>